import React from 'react';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';
import StationHelpder from '../services/station-helper';
import SEO from '../components/seo';
import SvgSprite from '@bfbs/storybook/dist/components/atoms/svg-sprite/svg-sprite';
import RadioPlayer from '@bfbs/storybook/dist/components/molecules/radio-player/radio-player';
import Container from '../components/container';

const Radioplayer = props => {
  // Create arguments from the URL.
  const args = props['*'].split('/');

  /**
   * Filter the Stations for the given ID.
   *
   * @param edges
   * @param id
   * @returns {*}
   */
  const filterStations = (edges, id) => {
    for (let i = 0; i < edges.length; i++) {
      if (edges[i].node.id === id) {
        return edges[i].node;
      }
    }
    return false;
  };

  /**
   * Filter the broadcasts for the given ID.
   *
   * @param edges
   * @param id
   * @returns {*}
   */
  const filterBroadcasts = (edges, id) => {
    return filterStations(edges, id);
  };

  /**
   * Filter the podcasts for the given ID.
   *
   * @param edges
   * @param id
   * @returns {*}
   */
  const filterPodcasts = (edges, id) => {
    for (let i = 0; i < edges.length; i++) {
      if (edges[i].id === id) {
        return edges[i];
      }
    }
    return false;
  };

  /**
   * Setup the require player data based on the URL arguments.
   *
   * @param args
   * @param data
   * @returns array|boolean
   */
  const preparePlayer = (args, data) => {
    const type = args[0] ? args[0] : null;
    const id = args[1] ? args[1] : null;

    if (!type || !id) {
      return false;
    }

    let playerData = false;

    switch (type) {
      case 'station':
        const station = filterStations(data.allStation.edges, id);
        if (station) {
          playerData = {
            title: station.title,
            url: StationHelpder.getStreamAacUrl(station.metadatastation.customUri),
            altUrl: StationHelpder.getStreamMp3Url(station.metadatastation.customUri),
            summary: station.description,
            live: true,
            heading: 'Live',
          };
        }
        break;
      case 'episode':
        const episode = filterBroadcasts(data.allBroadcast.edges, id);
        if (episode) {
          playerData = {
            title: episode.title,
            url: episode.field_values.listen_again_url,
            summary: episode.description,
            live: false,
            heading: 'Catch up',
          };
        }
        break;
      case 'podcast':
        const podcast = filterPodcasts(data.allFeedBfbsPodcastsItems.nodes, id);
        if (podcast) {
          playerData = {
            title: podcast.title,
            url: podcast.enclosure.url,
            summary: podcast.content,
            live: false,
            heading: 'Podcast',
          };
        }
        break;
      default:
        return false;
    }

    return playerData;
  };

  const playerData = preparePlayer(args, props.data);

  if (!playerData) {
    return <Redirect noThrow to="/404" />;
  }

  return (
    <main className="row--padding-y horizontal--side-spacing">
      <Container>
        <SEO title={`Now playing ${playerData.title}`} />
        <RadioPlayer {...playerData} />
        <SvgSprite />
      </Container>
    </main>
  );
};

export default Radioplayer;

// Get all the data we might need to play.
export const playerDataQuery = graphql`
  query playerAll {
    allStation {
      edges {
        node {
          bfbs_id
          id
          title
          description
          metadatastation {
            customUri {
              uri
              mime
              description
            }
          }
        }
      }
    }
    allBroadcast(filter: { field_values: { listen_again: { eq: true }, listen_again_url: { ne: null } } }) {
      edges {
        node {
          bfbs_id
          id
          title
          description
          field_values {
            listen_again_url
          }
        }
      }
    }
    allFeedBfbsPodcastsItems {
      nodes {
        id
        title
        content
        enclosure {
          url
        }
      }
    }
  }
`;
